<template>
  <div class="messageNotify">
    <BannerTop :icon-left="['back']" :show-icon-right="false" title="" />
    <div class="messageBox flex-row flex-al-center flex-jc-bt">
      <div class="messageTitle flex-row flex-al-center">
        <img
          src="https://img01.yzcdn.cn/upload_files/2022/05/06/FoVwEx7b-_EIopfmwaW9vA7CMqsK.png"
        />
        <span>消息通知</span>
      </div>
      <div class="messageSwitch">
        <van-switch
          type="switch"
          color="#3E8AFF"
          :model-value="switchStatus"
          @change="switchChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { changeMessageSendMark } from "@/project/smartHomeworkUserClient/API/user";
import BannerTop from "@/components/BannerTop";
import { Dialog } from "vant";

export default {
  components: { BannerTop },
  data() {
    return {
      switchStatus: false,
    };
  },
  computed: {
    ...mapState("smartHomeworkUserClient/user", ["studentUserInfo"]),
  },
  async mounted() {
    await this.$store.dispatch(
      "smartHomeworkUserClient/user/getStudentUserInfo"
    );
    this.switchStatus = Boolean(
      this.studentUserInfo.acceptMaskBookMarkedMessage
    );
  },
  methods: {
    switchChange(value) {
      if (!value) {
        Dialog.confirm({
          title: "提示",
          message:
            "<div class='dialogMes'><img src='https://img01.yzcdn.cn/upload_files/2022/05/13/Fo5ElABif2VHCLW3v0Ean4XuaQUd.png'/>消息通知关闭，您将无法收到教师的批阅完成消息，是否确认关闭？</div>",
          cancelButtonColor: "#333333",
          cancelButtonText: "关闭",
          confirmButtonColor: "#4593FF",
          confirmButtonText: "取消",
          allowHtml: true,
        })
          .then(() => {
            // on confirm
          })
          .catch(() => {
            changeMessageSendMark({
              id: this.studentUserInfo.id,
              state: Number(value),
            }).then((res) => {
              if (res.status === 200) {
                this.switchStatus = value;
                this.$store.dispatch(
                  "smartHomeworkUserClient/user/getStudentUserInfo",
                  true
                );
              }
            });
          });
      } else {
        changeMessageSendMark({
          id: this.studentUserInfo.id,
          state: Number(value),
        }).then((res) => {
          if (res.status === 200) {
            this.switchStatus = value;
            this.$store.dispatch(
              "smartHomeworkUserClient/user/getStudentUserInfo",
              true
            );
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.dialogMes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  color: #333333;
  img {
    width: 17px;
    height: 17px;
    margin-left: 10px;
    margin-right: -5px;
    margin-top: 1px;
  }
}
.messageNotify {
  height: 100vh;
  overflow: auto;
  background: linear-gradient(180deg, #ecf2f7 0%, rgba(233, 244, 254, 0) 100%);

  .messageBox {
    width: 355px;
    height: 75px;
    background: #ffffff;
    border-radius: 6px;
    margin: 10px auto;
    background: #ffffff;
    padding-left: 19px;
    padding-right: 20px;

    .messageTitle {
      img {
        width: 50px;
        height: 50px;
        margin-right: 13px;
      }

      text {
        font-size: 14px;
        color: #333333;
      }
    }

    .messageSwitch {
    }
  }
}
</style>
